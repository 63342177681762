// import store from '../store';

// 折線圖套件echarts.js
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { LegendComponent } from 'echarts/components';
echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer, UniversalTransition, LegendComponent]);

const useLine = (element) => {
  const lineCharts = echarts.init(element);
  const setOption = (data) => {
    const option = {
      color: ['#63b860'],
      grid: {
        show: true,
        borderColor: '#e2e2e2',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{c}',
      },
      legend: data.legend ? data.legend : {},
      xAxis: {
        name: data.xName,
        nameTextStyle: {
          color: '#b5b5b5',
          verticalAlign: 'top',
          padding: [8, 0, 0, 0],
        },
        nameLocation: 'end',
        type: 'category',
        boundaryGap: [0.2, 0.2],
        data: data.xValue,
        axisTick: {
          show: false, // 不顯示座標軸刻度
        },
        axisLine: {
          show: false, // 是否顯示座標軸
        },
        axisLabel: {
          textStyle: {
            color: '#b5b5b5',
          },
        },
      },
      yAxis: {
        name: data.yName,
        nameTextStyle: {
          color: '#b5b5b5',
          align: 'right',
          padding: [0, 0, 10, 0],
        },
        type: 'value',
        splitLine: {
          // 背景線
          lineStyle: {
            color: '#e2e2e2',
          },
        },
        boundaryGap: [0, 0.2], // 距離最低點, 最高點
        // boundaryGap是一個兩個值的數組，分別表示數據最小值和最大值的延伸範圍，可以直接設置數值或者相對的百分比，在設置min和max後無效
        axisLine: {
          show: true, // 是否顯示座標軸
          lineStyle: {
            color: '#e2e2e2',
          },
        },
        axisLabel: {
          textStyle: {
            color: '#b5b5b5',
          },
        },
      },
      series: data.series,
      // series: [
      // {
      //   data: data.yValue,
      //   type: 'line',
      //   symbol: 'circle', // 折線點設置為實心
      //   symbolSize: 10, // 折線點的大小
      // }
      // ],
    };

    return lineCharts.setOption(option);
  };
  const resize = () => lineCharts.resize();

  return { setOption, resize };
};

export { useLine };
