<template>
  <div class="trend-wrap" v-if="getSearchCondition != null">
    <el-form
      v-if="errorHint() == 'noerror'"
      style="
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
      "
    >
      <el-form-item style="margin-bottom: 0">
        <el-select v-model="category.options1.activeOption" placeholder="請選擇" style="width: 150px" size="default">
          <el-option
            v-for="(option, index) in category.options1.options"
            :key="'option' + index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        style="margin-bottom: 0"
        v-if="getCurrentTab == '2' || getCurrentTab == '3' || getCurrentTab == '4' || getCurrentTab == '5'"
      >
        <el-select
          v-model="category.options2.activeOption"
          placeholder="請選擇"
          style="width: 150px; margin-left: 10px"
          size="default"
        >
          <el-option
            v-for="(option, index) in category.options2.options"
            :key="'option' + index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 0" v-if="getCurrentTab == '2' || getCurrentTab == '4'">
        <el-select
          v-model="category.options3.activeOption"
          placeholder="請選擇"
          style="width: 150px; margin-left: 10px"
          size="default"
        >
          <el-option
            v-for="(option, index) in category.options3.options"
            :key="'option' + index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="trend" id="trend">
      <!-- <div ref="charts" style="width: 780px;height: 400px;"></div> -->
    </div>
    <div class="gray-hint" v-if="errorHint() == '請選擇門市'">
      請先在上方條件篩選欄位選擇門市，
      <br />
      以查看相關資訊
    </div>
    <div class="gray-hint" v-else-if="errorHint() == '尚無資料'">尚無資料</div>
  </div>
</template>
<script>
import { reactive, watch, nextTick } from 'vue'; // inject, ref
import { useGetters } from '@/utils/mapStore'; // useActions useMutations
import { useLine } from '@/utils/trend';
export default {
  name: 'trend',
  setup() {
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const { getDataAll, getSearchCondition, getCurrentTab } = useGetters('analysis', [
      'getDataAll',
      'getSearchCondition',
      'getCurrentTab',
    ]);

    const category = reactive({
      options1: {}, // 下拉1
      options2: {}, // 下拉2
      options3: {}, // 下拉3
    });
    // 產生下拉類別
    function createCategoryOptions1() {
      category.options1 = {};
      if (getDataAll.value.length != 0) {
        category.options1 = {
          activeOption: '1',
          options: [],
        };
        for (let i = 0; i < getDataAll.value.length; i++) {
          category.options1.options.push({
            label: getDataAll.value[i].name,
            value: `${i + 1}`,
          });
        }
      }
    }
    watch(
      () => getCurrentTab.value,
      () => {
        // 下拉的選項
        if (getCurrentTab.value == '1') {
          category.options1 = {
            activeOption: 'amount',
            options: [
              {
                label: '銷售總淨額',
                value: 'amount',
              },
              {
                label: '訂單筆數',
                value: 'count',
              },
              {
                label: '銷售數',
                value: 'item_count',
              },
              {
                label: '客單價',
                value: 'price',
              },
            ],
          };
          category.options2 = {};
          category.options3 = {};
        } else if (getCurrentTab.value == '2') {
          createCategoryOptions1();
          category.options2 = {
            activeOption: '',
            options: [],
          };
          category.options3 = {
            activeOption: 'count',
            options: [
              {
                label: '銷售數',
                value: 'count',
              },
              {
                label: '銷售總淨額',
                value: 'amount',
              },
            ],
          };
        } else if (getCurrentTab.value == '3') {
          category.options1.options = [];
          getDataAll.value[0].data.forEach((d, i) => {
            if (i == 0) category.options1.activeOption = d.event_name;
            category.options1.options.push({
              label: d.event_name,
              value: d.event_name,
            });
          });
          category.options2 = {
            activeOption: 'count',
            options: [
              {
                label: '使用次數',
                value: 'count',
              },
              {
                label: '累積金額',
                value: 'amount',
              },
            ],
          };
        } else if (getCurrentTab.value == '4') {
          createCategoryOptions1();
          category.options2 = {
            activeOption: '',
            options: [],
          };
          category.options3 = {
            activeOption: 'count',
            options: [
              {
                label: '使用次數',
                value: 'count',
              },
              {
                label: '折扣金額',
                value: 'amount',
              },
            ],
          };
        } else if (getCurrentTab.value == '5') {
          createCategoryOptions1();
          category.options2 = {
            activeOption: 'count',
            options: [
              {
                label: '使用次數',
                value: 'count',
              },
              {
                label: '累積金額',
                value: 'amount',
              },
            ],
          };
        }
      },
      {
        immediate: true,
      }
    );
    watch(
      [
        () => category.options1.activeOption,
        () => category.options2.activeOption,
        () => category.options3.activeOption,
        () => getDataAll.value,
      ],
      (newVal, oldVal) => {
        if (getDataAll.value.length == 0) return;
        // 下拉改變時 || 資料更新時
        // console.log('newVal:', newVal);
        // console.log('oldVal:', oldVal);
        if (getCurrentTab.value == '2' || getCurrentTab.value == '4') {
          if (JSON.stringify(newVal[3]) != JSON.stringify(oldVal[3])) {
            // 搜尋條件改變時，下拉1的options更新
            createCategoryOptions1();
          }
          if (newVal[0] != oldVal[0] || JSON.stringify(newVal[3]) != JSON.stringify(oldVal[3])) {
            category.options2 = { activeOption: '', options: [] };
            if (getCurrentTab.value == '2') category.options3.activeOption = 'count';
            if (getCurrentTab.value == '4') category.options3.activeOption = 'count';
            getDataAll.value[category.options1.activeOption - 1].data.forEach((data, index) => {
              if (index == 0) category.options2.activeOption = data.event_name;
              category.options2.options.push({ label: data.event_name, value: data.event_name });
            });
          }
          if (newVal[1] != oldVal[1]) {
            if (getCurrentTab.value == '2') category.options3.activeOption = 'count';
            if (getCurrentTab.value == '4') category.options3.activeOption = 'count';
          }
          if (newVal[1] != oldVal[1]) {
            if (newVal[1] != '' && newVal[2] == 'count' && getCurrentTab.value == '2')
              nextTick().then(() => createTrend());
            else if (newVal[1] != '' && newVal[2] == 'count' && getCurrentTab.value == '4')
              nextTick().then(() => createTrend());
            else nextTick().then(() => clearTrend());
          } else {
            if (newVal[2] != oldVal[2]) nextTick().then(() => createTrend());
            else nextTick().then(() => clearTrend());
          }
        } else if (getCurrentTab.value == '3') {
          if (JSON.stringify(newVal[3]) != JSON.stringify(oldVal[3])) {
            // 搜尋條件改變時，下拉1的options更新
            category.options1.options = [];
            getDataAll.value[0].data.forEach((d, i) => {
              if (i == 0) category.options1.activeOption = d.event_name;
              category.options1.options.push({
                label: d.event_name,
                value: d.event_name,
              });
            });
            if (newVal[0] == oldVal[0] && newVal[1] == 'count') {
              nextTick().then(() => createTrend());
            } else {
              category.options2.activeOption = 'count';
              if (oldVal[0] == undefined) oldVal[0] == nextTick().then(() => createTrend());
            }
            return;
          }
          if (newVal[0] != oldVal[0] && newVal[1] != 'count') category.options2.activeOption = 'count';
          else if (newVal[0] != oldVal[0] && newVal[1] == 'count') nextTick().then(() => createTrend());
          else nextTick().then(() => createTrend());
        } else if (getCurrentTab.value == '5') {
          if (JSON.stringify(newVal[3]) != JSON.stringify(oldVal[3])) {
            // 搜尋條件改變時，下拉1的options更新
            createCategoryOptions1();
            if (newVal[0] == oldVal[0] && newVal[1] == 'count') {
              nextTick().then(() => createTrend());
            } else {
              category.options2.activeOption = 'count';
              if (oldVal[0] == undefined) oldVal[0] == nextTick().then(() => createTrend());
            }
            return;
          }
          if (newVal[0] != oldVal[0] && newVal[1] != 'count') category.options2.activeOption = 'count';
          else if (newVal[0] != oldVal[0] && newVal[1] == 'count') nextTick().then(() => createTrend());
          else nextTick().then(() => createTrend());
        } else {
          nextTick().then(() => createTrend());
        }
      },
      {
        immediate: true,
      }
    );
    function clearTrend() {
      document.querySelector('#trend').innerHTML = '';
    }
    function createTrend() {
      if (getSearchCondition.value == null) return;
      if (errorHint() == '請選擇門市') return;
      if (getDataAll.value.length == 0) return;
      let xValue = [];
      let yValue = [];
      let series = [];
      let legend = {}; // 圖例
      let cate = '';
      let trendData;

      if (getCurrentTab.value == '2' || getCurrentTab.value == '4') {
        cate = category.options1.options[category.options1.activeOption - 1].label;
        trendData = getDataAll.value.filter((data) => data.name == cate)[0].data;
        if (trendData.length == 0) return;
        trendData = trendData.filter((data) => data.event_name == category.options2.activeOption)[0].data;
      } else if (getCurrentTab.value == '5') {
        cate = category.options1.options[category.options1.activeOption - 1].label;
        trendData = getDataAll.value.filter((data) => data.name == cate)[0].data;
        if (trendData.length == 0) return;
      } else {
        cate = 'all';
        trendData = getDataAll.value.filter((data) => data.name == cate)[0].data;
        if (trendData.length == 0) return;
        if (getCurrentTab.value == '3')
          trendData = trendData.filter((data) => data.event_name == category.options1.activeOption)[0].data;
        else trendData = trendData[0].data;
      }
      if (getCurrentTab.value == '5') {
        // 多條線

        let color = ['#63b860', '#3f91c5', '#f6b15f']; // 線的顏色
        xValue = [];
        legend = {
          type: 'plain',
          bottom: 'bottom',
          icon: 'rect', // 圖例icon的形狀
          itemWidth: 14, // 圖例icon的寬度
          itemHeight: 14, // 圖例icon的高度
          selectedMode: false, // 關閉點擊icon的效果
        };
        trendData[0].data.forEach((element) => {
          xValue.push(`${element.date.slice(4, 6)}/${element.date.slice(-2)}`);
        });

        trendData.forEach((data, i) => {
          yValue = [];
          // console.log('trendData.arr', data);
          data.data.forEach((element) => {
            // console.log('element',element[category.options2.activeOption]);
            yValue.push(element[category.options2.activeOption]);
          });

          series.push({
            name: data.event_name,
            data: yValue,
            type: 'line',
            symbol: 'circle', // 折線點設置為實心
            symbolSize: 10, // 折線點的大小
            lineStyle: {
              // 線的顏色
              color: color[i],
            },
            itemStyle: {
              // 點的顏色
              color: color[i],
            },
          });
        });
      } else {
        // 一條線
        trendData.forEach((data) => {
          xValue.push(data.date.slice(4, 6) + '/' + data.date.slice(6, 8));
          if (getCurrentTab.value == '2' || getCurrentTab.value == '4')
            yValue.push(data[category.options3.activeOption]); //下拉3
          else if (getCurrentTab.value == '3') yValue.push(data[category.options2.activeOption]); //下拉2
          else yValue.push(data[category.options1.activeOption]); //下拉1
        });
        series = [
          {
            data: yValue,
            type: 'line',
            symbol: 'circle', // 折線點設置為實心
            symbolSize: 10, // 折線點的大小
          },
        ];
      }
      // console.log('xValue-',xValue)
      // console.log('yValue-',yValue);
      document.querySelector('#trend').innerHTML = '<div id="charts" style="width: 780px;height: 400px;"></div>';
      const { setOption, resize } = useLine(document.querySelector('#charts'));
      let yName;
      if (getCurrentTab.value == '2' || getCurrentTab.value == '4')
        yName = `（${
          category.options3.options.filter((option) => option.value == category.options3.activeOption)[0].label
        }）`;
      else if (getCurrentTab.value == '3' || getCurrentTab.value == '5')
        yName = `（${
          category.options2.options.filter((option) => option.value == category.options2.activeOption)[0].label
        }）`;
      else
        yName = `（${
          category.options1.options.filter((option) => option.value == category.options1.activeOption)[0].label
        }）`;
      setOption({
        xName: '（日期）',
        yName: yName,
        xValue: xValue,
        series: series,
        legend: legend,
      });
      window.addEventListener('resize', () => {
        resize();
      });
    }
    function errorHint() {
      if (getSearchCondition.value.shop.id == '' && is_headquarters == 'true') return '請選擇門市';
      else if (getDataAll.value.length == 0 || getDataAll.value[0].data.length == 0) {
        nextTick().then(() => clearTrend());
        return '尚無資料';
      } else return 'noerror';
    }

    return {
      is_headquarters,
      errorHint,
      category,
      getSearchCondition,
      getDataAll,
      getCurrentTab,
    };
  },
};
</script>
<style lang="scss" scoped>
.trend-wrap {
  width: 100%;
  position: relative;
  padding: 20px 50px;
}
.gray-hint {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  color: var(--el-text-color-secondary);
}
</style>
